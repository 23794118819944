<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	 </div>
	<!-- menu_ico -->
	 <div class="gywm_lay1 content_lay" style="padding-top:50px;">
	   <h4 class="content_tit" style="padding:20px 0;font-size:24px;color:#1b1b1b;">{{lunboListe.title}}</h4>
     <div class="content_cont" style="    padding: 20px 0px;font-size: 16px;text-align: right;display: inline-block;width: 60%;">{{lunboListe.publishTime}}</div>
	   <div class="content_cont" style="padding-bottom:50px;font-size:16px;color:#1b1b1b;display:inline-block;width:60%;text-align:left;" v-html="lunboListe.remark">
	   </div>
	  </div>
	  <!-- content_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>

<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import moment from 'moment'

import axios from 'axios'
import {GETDATA, GETLISTDATA} from "@/apis/api";

export default {
  name: 'content',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		id: this.$route.query.id,
		lunboListe:[]
    };
  },
  mounted() {
	  this.showlunbo();
	  // if (location.href.indexOf("#reloaded") == -1) {
	  //    location.href = location.href + "#reloaded";
	  //    location.reload();
	  // };
  },
  methods:{
	  showlunbo(){
      let _this = this;
      GETDATA({id:this.id}).then((res) => {
        if(res.code == 200){
          _this.lunboListe = res.data;
          _this.lunboListe.publishTime = _this.dateFormat(_this.lunboListe.publishTime)
        }
      });
	  },
    dateFormat(value) {
      let formatString = formatString || 'YYYY年MM月DD日';
      return moment(value).format(formatString); // 这是时间戳转时间 HH:mm:ss
    }
  }
}
</script>